.nav-item-active
{
  background-color: #006cff !important;
}

*
{
  user-select: none;
}

.h-logo img
{
  height: 70px;
  object-fit: contain;
}